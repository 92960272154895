<template>
<div>
  <columns>
    <column>
      <data-selector
        tag-class="is-medium is-rounded"
        classes="is-medium is-rounded"
        delete-button-class="is-medium is-rounded is-danger"
        description="A list of branches that can work with this customer."
        multiple
        searchable
        required
        :items="branch_list"
        value-key="id"
        label-key="name"
        :value="location.branches"
        :error="$root.errors.branches"
        @input="updateBranches">
        Branches
      </data-selector>
    </column>
</columns>
  <columns>
    <column>
      <text-input
        classes="is-medium is-rounded"
        required
        :value="location.name"
        @input="updateName">
        Name
      </text-input>
    </column>
    <column>
      <text-input
        :error="$root.errors.legal_entity"
        classes="is-medium is-rounded"
        :value="location.legal_entity"
        @input="updateLegalEntity">
        Legal Name <small class="has-text-weight-light">(Optional)</small>
      </text-input>
    </column>
    <column>
      <text-input
        :error="$root.errors.reference"
        classes="is-medium is-rounded"
        :value="location.reference"
        @input="updateReference">
        Reference <small class="has-text-weight-light">(Optional)</small>
      </text-input>
    </column>
  </columns>
  <columns>
    <column>
      <physical-address 
        module="location"
        :address="location.address"
      />
    </column>
  </columns>
  <columns>
    <column>
      <submit-button
        class="is-medium is-rounded" 
        :working="saving"
        @submit="save">Save</submit-button>
    </column>
    <column v-if="!location.equipment_count && !location.work_orders_count" class="is-narrow">
      <action-button
        class="is-small is-danger is-rounded" 
        :disabled="saving"
        :working="deleting"
        @click="deleteLocation">Delete</action-button>
    </column>
  </columns>
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {

  data: () => ({
    saving: false,
    deleting: false
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadList')
  },

  methods: {
    ...mapMutations('location', [
      'updateName',
      'updateBranches',
      'updateLegalEntity',
      'updateReference'
    ]),
    save() {
      this.saving = true
      this.$store.dispatch('location/update').then(() => {
        this.saving = false
        this.$toast.success('saved')
      }).catch(() => {
        this.saving = false
      })
    },
    async deleteLocation() {
      if(await this.$confirm({
        title: "Delete Location",
        message: "Are you sure you want to delete this location?"
      })) {
        this.deleting = true
        this.$store.dispatch('location/deleteLocation').then(() => {
          this.deleting = false
          this.$toast.success('Deleted')
          this.$router.push({
            name: 'locations'
          })
        }).catch(() => {
          this.deleting = false
        })
      }
    },
    
  },

  computed: {
    ...mapGetters('location', [
      'location'
    ]),
    ...mapGetters('branch', [
      'branch_list'
    ]),
  }

}
</script>